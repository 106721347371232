<template>
    <div class="p-grid panel-demo">
        <div class="p-col-8 p-md-8">
            <div class="card p-fluid">
                <h5>Terms of Usage</h5>

            </div>
            <div class="card p-fluid"  >                
                Terms of usage, terms of usage, terms of usage...                 
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
             
        };
    },

    created() {
         
    },

    updated() {},

    watch: {
         
 
    },

    apiService: null,

    mounted() {
         
    },

    methods: {
         
      

         
    },
};
</script>

<style lang="scss" scoped>
 
</style>
